<template>
  <!-- Table Container Card -->
  <b-card no-body class="">
    <b-row class="p-1">
      <b-col cols="3" class="">
        <b-link
          to="/reports/networth"
          class="btn btn-outline-primary font-weight-bold d-block text-wrap"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="50" variant="light-primary">
                <feather-icon size="18" icon="TruckIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="my-1"> {{ $t("fields.networth") }}</h6>
            </b-media-body>
          </b-media>
        </b-link>
      </b-col>
    </b-row>
    <hr />
    <app-collapse accordion>
      <app-collapse-item
        v-for="(item, index) in appData"
        :key="'itemlist' + index"
        :title="$t(item.title)"
      >
        <b-row>
          <b-col
            v-for="(itemx, indexx) in item.children"
            :key="'itemlistz' + indexx"
            cols="12"
            sm="12"
            md="3"
            class="mt-1 d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <b-card-body class="border border-primary rounded p-0">
              <b-link
                :to="{
                  name: itemx.name,
                }"
                class="font-weight-bold d-block text-wrap"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded size="50" variant="light-primary">
                      <feather-icon size="18" :icon="itemx.icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="my-1">
                      {{ $t(itemx.title) }}
                    </h6>
                  </b-media-body>
                </b-media>
              </b-link>
            </b-card-body>
          </b-col>
        </b-row>
        <app-collapse accordion>
          <app-collapse-item
            v-for="(itemx, indexx) in item.childrenx"
            :key="'itemlist' + indexx"
            :title="$t(itemx.title)"
          >
            <b-row>
              <b-col
                v-for="(itemxx, indexx) in itemx.children"
                :key="'itemlistz' + indexx"
                cols="12"
                sm="12"
                md="3"
                class="mt-1 d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <b-card-body class="border border-primary rounded p-0">
                  <b-link
                    :to="{
                      name: itemxx.name,
                    }"
                    class="font-weight-bold d-block text-wrap"
                  >
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar rounded size="50" variant="light-primary">
                          <feather-icon size="18" :icon="itemxx.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="my-1">
                          {{ $t(itemxx.title) }}
                        </h6>
                      </b-media-body>
                    </b-media>
                  </b-link>
                </b-card-body>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

  <script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import vSelect from "vue-select";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardHeader,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "reports";
    const modulename = "Reports";
    const modulenamesub = "reports";

    const appData = [
      {
        title: "navigation.physicalassets",
        children: [
          {
            title: "navigation.realestate",
            name: "reports-physicalassets-realestate",
            icon: "BookmarkIcon",
          },
          {
            title: "navigation.householeqipment",
            name: "reports-physicalassets-householdequipments",
            icon: "HomeIcon",
          },
          {
            title: "navigation.automobile",
            name: "reports-physicalassets-automobile",
            icon: "TruckIcon",
          },
          {
            title: "navigation.preciousmet",
            name: "reports-physicalassets-preciousmetals",
            icon: "SlackIcon",
          },
          {
            title: "navigation.artsclotible",
            name: "reports-physicalassets-artsandcollectible",
            icon: "BriefcaseIcon",
          },
          {
            title: "navigation.locker",
            name: "reports-physicalassets-locker",
            icon: "LockIcon",
          },
        ],
      },
      {
        title: "navigation.financialassets",
        children: [
          {
            title: "navigation.bank",
            name: "reports-financialassets-bank",
            icon: "DollarSignIcon",
          },
          {
            title: "navigation.wallet",
            name: "reports-financialassets-wallet",
            icon: "TruckIcon",
          },
          {
            title: "navigation.cash",
            name: "reports-financialassets-cash",
            icon: "TruckIcon",
          },
          {
            title: "navigation.fixeddeposits",
            name: "reports-financialassets-fixeddeposits",
            icon: "TruckIcon",
          },
          {
            title: "navigation.retirals",
            name: "reports-financialassets-retirals",
            icon: "AlignJustifyIcon",
          },

          {
            title: "navigation.ssy",
            name: "reports-financialassets-ssy",
            icon: "AlignJustifyIcon",
          },

          {
            title: "navigation.receivables",
            name: "reports-financialassets-receivables",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.recurringdeposits",
            name: "reports-financialassets-recurringdeposits",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.mutualfundssip",
            name: "reports-financialassets-mutualfunds",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.securities",
            name: "reports-financialassets-securities",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.nsc",
            name: "reports-financialassets-nsc",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.crypto",
            name: "reports-financialassets-crypto",
            icon: "AlignJustifyIcon",
          },
        //   {
        //     title: "navigation.lifeinsurance",
        //     name: "reports-financialassets-lifeinsurance",
        //     icon: "AlignJustifyIcon",
        //   },
        ],
      },
      {
        title: "navigation.generic",
        children: [],
        childrenx: [
          {
            title: "navigation.assistance",
            children: [
              {
                title: "navigation.importantcontacts",
                name: "reports-assistance-importantcontacts",
                icon: "PhoneCallIcon",
              },
              {
                title: "navigation.supportstaff",
                name: "reports-assistance-supportstaff",
                icon: "HomeIcon",
              },
            ],
          },
          {
            title: "navigation.utilities",
            children: [
              {
                title: "navigation.water",
                name: "reports-utilities-water",
                icon: "DropletIcon",
              },
              {
                title: "navigation.electricity",
                name: "reports-utilities-electricity",
                icon: "ZapOffIcon",
              },
              {
                title: "navigation.cabletvdth",
                name: "reports-utilities-cabletvdth",
                icon: "TvIcon",
              },
              {
                title: "navigation.mobile",
                name: "reports-utilities-mobile",
                icon: "SmartphoneIcon",
              },
              {
                title: "navigation.landbroad",
                name: "reports-utilities-landbroad",
                icon: "VoicemailIcon",
              },
              {
                title: "navigation.gas",
                name: "reports-utilities-gas",
                icon: "ShieldIcon",
              },
            ],
          },
          {
            title: "navigation.leisure",
            children: [
              {
                title: "navigation.subscriptions",
                name: "reports-leisure-subscriptions",
                icon: "YoutubeIcon",
              },
              {
                title: "navigation.occasion",
                name: "reports-leisure-occasion",
                icon: "GiftIcon",
              },
              {
                title: "navigation.membership",
                name: "reports-leisure-membership",
                icon: "UsersIcon",
              },
              {
                title: "navigation.travel",
                name: "reports-leisure-travel",
                icon: "UsersIcon",
              },
            ],
          },

        ],
      },
      {
        title: "navigation.health",
        children: [
          {
            title: "navigation.insurance",
            name: "reports-health-insurance",
            icon: "HeartIcon",
          },
          {
            title: "navigation.opd",
            name: "reports-health-opd",
            icon: "AlignJustifyIcon",
          },
        //   {
        //     title: "navigation.generalvaccination",
        //     name: "reports-health-generalvaccination",
        //     icon: "AlignJustifyIcon",
        //   },
          {
            title: "navigation.cards",
            name: "reports-health-cards",
            icon: "AlignJustifyIcon",
          },
        ],
      },
      {
        title: "navigation.children",
        children: [
          {
            title: "navigation.schooling",
            name: "reports-children-school",
            icon: "HeartIcon",
          },
        //   {
        //     title: "navigation.vaccination",
        //     name: "reports-children-vaccination",
        //     icon: "AlignJustifyIcon",
        //   },
        ],
      },
      {
        title: "navigation.cashflow",
        children: [
          {
            title: "navigation.transaction",
            name: "reports-cashflow-transaction",
            icon: "HeartIcon",
          },
          {
            title: "navigation.transfer",
            name: "reports-cashflow-transfer",
            icon: "AlignJustifyIcon",
          },
        ],
      },
      {
        title: "navigation.liabilities",
        children: [
          {
            title: "navigation.loan",
            name: "reports-liabilities-loan",
            icon: "HeartIcon",
          },
          {
            title: "navigation.creditcards",
            name: "reports-liabilities-creditcards",
            icon: "AlignJustifyIcon",
          },
          {
            title: "navigation.paylater",
            name: "reports-liabilities-paylater",
            icon: "AlignJustifyIcon",
          }, {
            title: "navigation.payables",
            name: "reports-liabilities-payables",
            icon: "AlignJustifyIcon",
          },

        ],
      },
    //   {
    //     title: "navigation.investment",
    //     children: [
    //       {
    //         title: "navigation.recurringdeposits",
    //         name: "reports-investment-recurringdeposits",
    //         icon: "CreditCardIcon",
    //       },
    //     ],
    //   },
      {
        title: "navigation.insurance",
        children: [
          {
            title: "navigation.life",
            name: "reports-insurance-life",
            icon: "HeartIcon",
          },
          {
            title: "navigation.general",
            name: "reports-insurance-general",
            icon: "AlignJustifyIcon",
          },
        ],
      },
    ];
    return {
      modulename,
      modulenamesub,
      appData,
      GENAPP_APP_STORE_MODULE_NAME,
    };
  },
  methods: {},
};
</script>

  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.electricity-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
